<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-card-title>
              <h4 class="card-title">Search Projects</h4>
            </b-card-title>
            <div class="col-3">
              <FormulateForm name="createManufacture" v-model="values" :schema="searchScheme" @submit="submitHandler">
                <FormulateInput type="submit">
                  <span v-if="performAction">
                    <b-spinner class="vueformulate-loader"></b-spinner>
                    <span> Searching... </span>
                  </span>
                  <span v-else>Search Project</span>
                </FormulateInput>
              </FormulateForm>
              <b-alert show variant="danger" class="mt-2" v-if="projectNumber && !searchResult">Project Not found
              </b-alert>
              <button class="btn btn-primary" type="button" @click="createNew" v-if="projectNumber && !searchResult">
                Create new Project
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template> 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";

/**
 * Add Project Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Projects",
      items: [
        {
          text: "Create",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      projectNumber: "",
      performAction: false,
      values: {},
      searchResult: undefined,
      searchScheme: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Project Number",
          "validation-messages": { required: "Project Number is required" },

          validation: "required",
        },
      ],
    };
  },
  computed: {},
  mounted() { },
  methods: {
    createNew() {
      this.$router.push({
        path: "edit-projects",
        query: { id: this.searchResult || this.projectNumber },
      });
    },
    async submitHandler(data) {
      console.log(data);
      this.performAction = true;
      this.searchProject(data);
    },
    searchProject(data) {
      this.projectNumber = "";
      // Copying object instead of reference
      const api = projects.model.search;
      // Now won't affect the original projects.model.search object
      api.id = data.name;
      this.generateAPI(api).then((res) => {
        this.searchResult = res.data.project;
        if (!this.searchResult) {
          this.projectNumber = data.name;
        } else {
          this.$router.push({
            path: "project-info",
            query: { id: data.name },
          });
        }
        console.log(res.data.project);
        this.performAction = false;
      });
    },
  },
};
</script>